import React from 'react';
import UserInfo from '../user-info/user-info';
import classNames from 'classnames';
import { ReviewProps } from './review-props';
import {
  REVIEW_CONTENT,
  LIST_ITEM,
  PENDING,
  REVIEW_RATING,
  REVIEW_TITLE,
  REVIEW_BODY,
  REVIEW_MEDIA,
  REVIEW_STATUS_BADGE,
} from '../../../common/constants/data-hooks';
import ListItemContainer from '../list-item-container/list-item-container';
import { classes } from './review-desktop.st.css';
import TextExtended from '../text-extended/text-extended';
import { RatingsInReview } from '../ratings-in-reviews/rating-in-reviews';
import ThumbnailsList from './thumbnails-list';
import CollapsibleText from '../collapsible-text/collapsible-text';
import { useFormSettings } from '~reviews/common/store/configuration/use-form-settings';
import VerifiedBadge from './verified-badge';

const ReviewDesktop: React.FC<ReviewProps> = ({
  author,
  content,
  verified,
  statusBadge,
  createdAt,
  actionsMenu,
  id,
  footer,
  openMediaModal,
  isPending,
  onShowMoreClick,
}) => {
  const { title, body, media } = useFormSettings();

  return (
    <ListItemContainer
      id={id}
      isReply={false}
      dataHook={classNames(LIST_ITEM, REVIEW_CONTENT, { [PENDING]: isPending })}
      vaidas-valdo-suck="ggc"
      isPending={isPending}
    >
      <div className={classes.header}>
        <div className={classes.leftSide}>
          <UserInfo author={author} time={createdAt} />
        </div>
        <div className={classes.rightSide}>{actionsMenu}</div>
      </div>
      {statusBadge ? (
        <div className={classes.statusBadge} data-hook={REVIEW_STATUS_BADGE}>
          {statusBadge}
        </div>
      ) : null}
      <RatingsInReview value={content.rating} verified={verified} dataHook={REVIEW_RATING} />
      {title.enabled ? (
        <div className={classes.title}>
          <TextExtended
            fontMapping="reviewTitle"
            colorMapping="reviewTitle"
            data-hook={REVIEW_TITLE}
          >
            {content.title}
          </TextExtended>
        </div>
      ) : null}
      {body.enabled && content.body ? (
        <CollapsibleText
          clamp={5}
          className={classes.body}
          onClick={onShowMoreClick}
          text={content.body}
          dataHook={REVIEW_BODY}
        />
      ) : null}
      {content.media.length > 0 && media.enabled && openMediaModal && (
        <div className={classes.media} data-hook={REVIEW_MEDIA}>
          <ThumbnailsList media={content.media} onClick={openMediaModal} />
        </div>
      )}
      {footer && <div className={classes.footer}>{footer}</div>}
    </ListItemContainer>
  );
};

export default ReviewDesktop;
