import React from 'react';
import { getAppState } from '../../../common/store/reviews/reviews-selectors';
import { useApi } from '../api-provider/use-api';
import { isLoggingEnabled, isDebug } from '../../../common/store/base-params/base-params-selectors';
import { unreachable } from '~/ts-utils';
import ListFetchErrorState from '../../empty-states/list-fetch-error-state';
import AppLoadingState from '../../empty-states/app-loading-state';
import DebugButton from '../dev-pannel/debug-button';
import OverlayLayerContainer from './overlay-layer-container';
import { withApiContextGuard } from '../api-provider/api-provider';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { AppReady } from './app-ready';
import { classes } from './app.st.css';
import { InfoAlert } from '~reviews/Widget/components/info-alert/info-alert';

const App: React.FC = React.memo(() => {
  const { isSSR, isEditor } = useEnvironment();
  const { refetchReviews, unmountResource, resourceState, shouldLog, biAppLoaded } = useApi(
    (state, actions) => ({
      refetchReviews: actions.refetchReviewsInitial,
      unmountResource: actions.unmountResource,
      biAppLoaded: actions.biAppLoaded,
      shouldLog: isLoggingEnabled(state),
      resourceState: state.reviews,
    }),
  );
  const appState = getAppState(resourceState);

  if (shouldLog) {
    console.log('[Reviews component state]', appState);
  }

  React.useEffect(() => {
    return () => {
      !isEditor && unmountResource({});
    };
  }, [isEditor, unmountResource]);

  React.useEffect(() => {
    // we check if it's not SSR, because if it is true, then it means this is initial hydration with SSR props and there is no controller yet
    if (!isSSR) {
      if (typeof performance !== 'undefined') {
        performance.mark && performance.mark('wr-reviews-rendered');
        biAppLoaded();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (appState.type) {
    case 'ERROR': {
      return (
        <Container>
          <ListFetchErrorState onRetry={() => refetchReviews()} />
        </Container>
      );
    }
    case 'PENDING': {
      return (
        <Container>
          <AppLoadingState />
        </Container>
      );
    }
    case 'READY': {
      return (
        <Container>
          <AppReady appState={appState} />
        </Container>
      );
    }
    default:
      throw unreachable(appState);
  }
});

App.displayName = 'App';

const Container: React.FC<React.PropsWithChildren> = ({ children }) => {
  const showDebugButton = useApi((state) => isDebug(state));
  const { isEditor, isPreview } = useEnvironment();
  const { t } = useTranslation();
  const shouldShowDemoAlert = isEditor || isPreview;

  const { biAppIsInViewport } = useApi((state, actions) => ({
    biAppIsInViewport: actions.biAppIsInViewport,
  }));

  const setContainerRef = React.useCallback((ref: HTMLDivElement) => {
    if (ref) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            biAppIsInViewport();
            observer.unobserve(ref);
          }
        },
        {
          threshold: 0,
        },
      );
      observer.observe(ref);
    }
  }, []);

  return (
    <React.Fragment>
      {shouldShowDemoAlert && (
        <div className={classes.infoContainer}>
          <InfoAlert alertText={t('demoContent.alert')} />
        </div>
      )}
      {showDebugButton && <DebugButton />}
      <OverlayLayerContainer />
      <div ref={setContainerRef}>{children}</div>
    </React.Fragment>
  );
};

export default withApiContextGuard(App);
